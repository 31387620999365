<template>
  <div>
    <CCard>
      <CCardHeader>
        <i class="fa fa-filter"></i>&nbsp; Filtrar por
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol sm="3">
            <CInput label="Desde" v-model="filter.desde" type="date"></CInput>
          </CCol>
          <CCol sm="3">
            <CInput label="Hasta" v-model="filter.hasta" type="date"></CInput>
          </CCol>
          <CCol sm="3">
            <button
              type="button"
              style="margin-top: 5.5%; width: 25%"
              :class="btnClasses"
              @click="mtdSearch"
            >
              <i class="fas fa-search"></i>&nbsp; Buscar
            </button>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
    <CCard v-if="data.length > 0">
      <CCardHeader> <i class="fa fa-book"></i>&nbsp; Datos </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol xxl="12" lg="12" md="12" sm="12" xs="12" class="mt-2">
            <CTabs variant="pills" vertical>
              <CTab active >
                <template slot="title">
                  <CIcon name="cil-calculator" /> {{ tabs[0] }}: Total crédito S/{{cpTotal}}
                </template>
                <div class="position-relative table-responsive">
                  <table class="table table-striped table-bordered">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Fecha</th>
                        <th>Cliente</th>
                        <th>Producto</th>
                        <th>Cantidad</th>
                        <th>Precio</th>
                        <th>Subtotal</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in data" :key="index">
                        <td>{{index + 1 }}</td>
                        <td>
                          {{
                            item.date.substr(8, 2) +
                            "/" +
                            item.date.substr(5, 2) +
                            "/" +
                            item.date.substr(0, 4)
                          }}
                        </td>
                        <td>{{ item.client }}</td>
                        <td>{{ item.product }}</td>
                        <td>{{ item.quantity }}</td>
                        <td>{{ item.price }}</td>
                        <td class="text-right">{{ parseFloat(item.subtotal).toFixed(2) }}</td>
                      </tr>
                      <tr>
                        <th colspan="6" class="text-right">Total credito</th>
                        <th class="text-right">{{ cpTotal }}</th>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </CTab>
              <CTab>
                <template slot="title">
                  <CIcon name="cil-basket" /> {{ tabs[1] }}: Total pagado S/{{cpTotalPagos}}
                </template>
                <div class="position-relative table-responsive">
                  <table class="table table-striped table-bordered">
                    <thead>
                      <tr>
                        <th>id</th>
                        <th>Fecha</th>
                        <th>Cliente</th>
                        <th>Pagado</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in charge" :key="index">
                        <td>{{index + 1}}</td>
                        <td>{{
                            item.date.substr(8, 2) +
                            "/" +
                            item.date.substr(5, 2) +
                            "/" +
                            item.date.substr(0, 4)
                          }}</td>
                        <td>{{item.client}}</td>
                        <td class="text-right">{{item.amount}}</td>
                      </tr>
                      <tr>
                        <th colspan="3" class="text-right">Total pagado</th>
                        <th class="text-right">{{ cpTotalPagos }}</th>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </CTab>
            </CTabs>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions } from "vuex";
import { bus } from "../../main";

export default {
  name: "v-report-sale",
  data() {
    return {
      tabs: ["Créditos", "Pagos"],
      activeTab: 1,
      filter: {
        desde: null,
        hasta: null,
      },
      data: [],
      charge:[]
    };
  },
  created() {
    let hoy = moment().format('L').toString();
    this.filter.desde=hoy.substring(6, 10)+"-"+hoy.substring(3,5)+"-"+hoy.substring(0,2);
    this.filter.hasta=hoy.substring(6, 10)+"-"+hoy.substring(3,5)+"-"+hoy.substring(0,2);
    this.mtdSearch();
  },
  computed: {
    btnClasses() {
      return [`mt-4 btn btn-${this.color || "primary"}`];
    },
    cpTotal() {
      let total = 0;
      this.data.forEach((element) => {
        total = parseFloat(total) + parseFloat(element.subtotal);
      });
      return parseFloat(total).toFixed(2);
    },
    cpTotalPagos(){
      let total = 0;
      this.charge.forEach((element) => {
        total = parseFloat(total) + parseFloat(element.amount);
      });
      return parseFloat(total).toFixed(2);
    }
  },
  methods: {
    ...mapActions(["get", "post"]),
    mtdSearch: function () {
      this.post({
        url: this.$store.getters.get__url + "/report/sale",
        token: this.$store.getters.get__token,
        params: this.filter,
      })
        .then((response) => {
          console.log(response);
          this.data = response.data;
          this.charge = response.charge;
        })
        .catch((errors) => {
          // this.errorsBackend = errors;
          // this.$emit("error", this.event);
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
